// extracted by mini-css-extract-plugin
export default {"colorBlack":"#1d2126","colorBlackTransparent":"#1d212680","colorBlue":"#40618e","colorBlueDark":"#0c65a7","colorBlueLighter":"#ebf4fa","colorGrayDark":"#5b656d","colorGrayDarker":"#313d48","colorGrayDarkest":"#24292f","colorGrayLight":"#adb2b6","colorGrayLighter":"#d6d8da","colorGrayLightest":"#ebeced","colorGreen":"#26b899","colorGreenLightest":"#e9f8f5","colorRed":"#e76f67","colorRedLightest":"#fdf1f0","colorText":"#24292f","colorWhite":"#fff","colorYellow":"#f6b551","fontWeightThin":"200","fontWeightRegular":"400","fontWeightSemiBold":"600","fontWeightBold":"700","fontSize10":".625rem","zIndexNeg1":"-1","zIndex0":"0","zIndex1":"1","zIndex2":"2","zIndex3":"3","zIndex4":"4","zIndex5":"5","notes":"notes_a00b0d2","header":"header_efd2c71","closeButton":"closeButton_e3505f6","sprite":"sprite_f86e9e8","body":"body_a0e3c45","noteList":"noteList_e187c1a","noteListItem":"noteListItem_cbac43b","noteListItemContent":"noteListItemContent_c97d4d7","noteListItemAuthor":"noteListItemAuthor_f4d0ba2","emptyNoteList":"emptyNoteList_fbfa480","emptyNoteListInner":"emptyNoteListInner_e9c5269","footer":"footer_e9d9d22","form":"form_c5a8738","selectCompanyContainer":"selectCompanyContainer_ded38cb","formRow":"formRow_d6efb01","formInput":"formInput_d346bd5","formSubmit":"formSubmit_c2cfd62"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"colorBlack\":\"#1d2126\",\"colorBlackTransparent\":\"#1d212680\",\"colorBlue\":\"#40618e\",\"colorBlueDark\":\"#0c65a7\",\"colorBlueLighter\":\"#ebf4fa\",\"colorGrayDark\":\"#5b656d\",\"colorGrayDarker\":\"#313d48\",\"colorGrayDarkest\":\"#24292f\",\"colorGrayLight\":\"#adb2b6\",\"colorGrayLighter\":\"#d6d8da\",\"colorGrayLightest\":\"#ebeced\",\"colorGreen\":\"#26b899\",\"colorGreenLightest\":\"#e9f8f5\",\"colorRed\":\"#e76f67\",\"colorRedLightest\":\"#fdf1f0\",\"colorText\":\"#24292f\",\"colorWhite\":\"#fff\",\"colorYellow\":\"#f6b551\",\"fontWeightThin\":\"200\",\"fontWeightRegular\":\"400\",\"fontWeightSemiBold\":\"600\",\"fontWeightBold\":\"700\",\"fontSize10\":\".625rem\",\"zIndexNeg1\":\"-1\",\"zIndex0\":\"0\",\"zIndex1\":\"1\",\"zIndex2\":\"2\",\"zIndex3\":\"3\",\"zIndex4\":\"4\",\"zIndex5\":\"5\",\"notes\":\"notes_a00b0d2\",\"header\":\"header_efd2c71\",\"closeButton\":\"closeButton_e3505f6\",\"sprite\":\"sprite_f86e9e8\",\"body\":\"body_a0e3c45\",\"noteList\":\"noteList_e187c1a\",\"noteListItem\":\"noteListItem_cbac43b\",\"noteListItemContent\":\"noteListItemContent_c97d4d7\",\"noteListItemAuthor\":\"noteListItemAuthor_f4d0ba2\",\"emptyNoteList\":\"emptyNoteList_fbfa480\",\"emptyNoteListInner\":\"emptyNoteListInner_e9c5269\",\"footer\":\"footer_e9d9d22\",\"form\":\"form_c5a8738\",\"selectCompanyContainer\":\"selectCompanyContainer_ded38cb\",\"formRow\":\"formRow_d6efb01\",\"formInput\":\"formInput_d346bd5\",\"formSubmit\":\"formSubmit_c2cfd62\"}";
        // 1734120687098
        var cssReload = require("../../../../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  