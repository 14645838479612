// extracted by mini-css-extract-plugin
export default {"colorBlack":"#1d2126","colorBlackTransparent":"#1d212680","colorBlue":"#40618e","colorBlueDark":"#0c65a7","colorBlueLighter":"#ebf4fa","colorGrayDark":"#5b656d","colorGrayDarker":"#313d48","colorGrayDarkest":"#24292f","colorGrayLight":"#adb2b6","colorGrayLighter":"#d6d8da","colorGrayLightest":"#ebeced","colorGreen":"#26b899","colorGreenLightest":"#e9f8f5","colorRed":"#e76f67","colorRedLightest":"#fdf1f0","colorText":"#24292f","colorWhite":"#fff","colorYellow":"#f6b551","fontWeightThin":"200","fontWeightRegular":"400","fontWeightSemiBold":"600","fontWeightBold":"700","fontSize10":".625rem","zIndexNeg1":"-1","zIndex0":"0","zIndex1":"1","zIndex2":"2","zIndex3":"3","zIndex4":"4","zIndex5":"5","type-body-1":"type-body-1_ff05202","type-body-2":"type-body-2_c4c6e3f","type-body-3":"type-body-3_a0c7e2b","type-body-4":"type-body-4_ae6ab1c","type-section-title":"type-section-title_ab330ec","type-notes":"type-notes_dfd64ef","type-item-title":"type-item-title_bdf4bd4","type-item-subtitle":"type-item-subtitle_bf06fa2","type-subtitle":"type-subtitle_bdfa067","type-title":"type-title_ec13d64","modelTrim":"modelTrim_f24eff9","yearMake":"yearMake_dce4447","vehicleDetails":"vehicleDetails_aadd8f2","image":"image_b56bec2","imageContainer":"imageContainer_e19f350","vehicleDetailsContent":"vehicleDetailsContent_dda4072","title":"title_e1d6418","bottom":"bottom_fd24437","mileage":"mileage_e370769","badges":"badges_d6b0ef5","vin":"vin_ca5c420","statusLabel":"statusLabel_cde47d7","AWAITING_CHECKOUT":"AWAITING_CHECKOUT_e1322c8","IN_IF_BID":"IN_IF_BID_e8be7b4","NO_SALE":"NO_SALE_c8fb17b","SOLD":"SOLD_e712c66","UPCOMING":"UPCOMING_e877f30"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"colorBlack\":\"#1d2126\",\"colorBlackTransparent\":\"#1d212680\",\"colorBlue\":\"#40618e\",\"colorBlueDark\":\"#0c65a7\",\"colorBlueLighter\":\"#ebf4fa\",\"colorGrayDark\":\"#5b656d\",\"colorGrayDarker\":\"#313d48\",\"colorGrayDarkest\":\"#24292f\",\"colorGrayLight\":\"#adb2b6\",\"colorGrayLighter\":\"#d6d8da\",\"colorGrayLightest\":\"#ebeced\",\"colorGreen\":\"#26b899\",\"colorGreenLightest\":\"#e9f8f5\",\"colorRed\":\"#e76f67\",\"colorRedLightest\":\"#fdf1f0\",\"colorText\":\"#24292f\",\"colorWhite\":\"#fff\",\"colorYellow\":\"#f6b551\",\"fontWeightThin\":\"200\",\"fontWeightRegular\":\"400\",\"fontWeightSemiBold\":\"600\",\"fontWeightBold\":\"700\",\"fontSize10\":\".625rem\",\"zIndexNeg1\":\"-1\",\"zIndex0\":\"0\",\"zIndex1\":\"1\",\"zIndex2\":\"2\",\"zIndex3\":\"3\",\"zIndex4\":\"4\",\"zIndex5\":\"5\",\"type-body-1\":\"type-body-1_ff05202\",\"type-body-2\":\"type-body-2_c4c6e3f\",\"type-body-3\":\"type-body-3_a0c7e2b\",\"type-body-4\":\"type-body-4_ae6ab1c\",\"type-section-title\":\"type-section-title_ab330ec\",\"type-notes\":\"type-notes_dfd64ef\",\"type-item-title\":\"type-item-title_bdf4bd4\",\"type-item-subtitle\":\"type-item-subtitle_bf06fa2\",\"type-subtitle\":\"type-subtitle_bdfa067\",\"type-title\":\"type-title_ec13d64\",\"modelTrim\":\"modelTrim_f24eff9\",\"yearMake\":\"yearMake_dce4447\",\"vehicleDetails\":\"vehicleDetails_aadd8f2\",\"image\":\"image_b56bec2\",\"imageContainer\":\"imageContainer_e19f350\",\"vehicleDetailsContent\":\"vehicleDetailsContent_dda4072\",\"title\":\"title_e1d6418\",\"bottom\":\"bottom_fd24437\",\"mileage\":\"mileage_e370769\",\"badges\":\"badges_d6b0ef5\",\"vin\":\"vin_ca5c420\",\"statusLabel\":\"statusLabel_cde47d7\",\"AWAITING_CHECKOUT\":\"AWAITING_CHECKOUT_e1322c8\",\"IN_IF_BID\":\"IN_IF_BID_e8be7b4\",\"NO_SALE\":\"NO_SALE_c8fb17b\",\"SOLD\":\"SOLD_e712c66\",\"UPCOMING\":\"UPCOMING_e877f30\"}";
        // 1734120692666
        var cssReload = require("../../../../../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  